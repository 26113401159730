import React, { useEffect, useRef } from 'react';
import { Container } from '@mui/material';
import PostForm from '../PostForm';
import PostList from '../PostList';
import CreativeObject3D from '../CreativeObject3D';
import { gsap } from 'gsap';
import './DashboardPage.css';
import Footer from '../Footer';

function DashboardPage() {
  const titleRef = useRef([]);

  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, scale: 0.5, y: -50 },
      { opacity: 1, scale: 1, y: 0, stagger: 0.05, duration: 2, ease: 'elastic.out(1, 0.75)' }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <header className="dashboard-header">
        <div className="header-content">
          <div className="title-3d">
            {'Dashboard'.split('').map((char, index) => (
              <span key={index} ref={(el) => (titleRef.current[index] = el)}>
                {char}
              </span>
            ))}
          </div>
        </div>
      </header>
      <CreativeObject3D />
      <PostForm />
      <PostList />
      <Footer />
    </Container>
  );
}

export default DashboardPage;
