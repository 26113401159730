import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import './RhymeSuggestions.css';

const RhymeSuggestions = ({ word, onSelectRhyme, language = 'en' }) => {
  const [rhymes, setRhymes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentBatch, setCurrentBatch] = useState([]); // Current batch of rhymes to display
  const [uniqueRhymes, setUniqueRhymes] = useState([]); // Full list of unique rhymes

  // Debounce the fetch function to optimize API calls
  const fetchRhymes = useCallback(
    debounce(async (word) => {
      try {
        if (!word) return;
        setLoading(true);
        setError('');

        let apiUrl = '';
        switch (language) {
          case 'en':
            apiUrl = `https://api.datamuse.com/words?rel_rhy=${word}&max=50`;
            break;
          case 'it':
          case 'es':
          case 'fr':
          case 'de':
          case 'ru':
            apiUrl = `https://rhymebrain.com/talk?function=getRhymes&word=${word}&lang=${language}`;
            break;
          default:
            throw new Error('Language not supported.');
        }

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch rhymes. Status: ${response.status}`);
        }

        const data = await response.json();
        if (!data || data.length === 0) {
          throw new Error('No rhymes found for this word.');
        }

        // Extract unique rhymes
        const uniqueRhymesList = Array.from(new Set(data.map((rhyme) => rhyme.word))).map((word) =>
          data.find((rhyme) => rhyme.word === word)
        );
        setUniqueRhymes(uniqueRhymesList);

        // Show the first batch of 6 rhymes
        setCurrentBatch(uniqueRhymesList.slice(0, 6));
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message || 'Failed to load rhymes. Please try again.');
      } finally {
        setLoading(false);
      }
    }, 300),
    [language]
  );

  useEffect(() => {
    if (word) {
      fetchRhymes(word);
    }
  }, [word, fetchRhymes]);

  // Handle shuffle to show 6 random rhymes
  const handleShuffle = () => {
    if (uniqueRhymes.length > 0) {
      const shuffledRhymes = [...uniqueRhymes].sort(() => Math.random() - 0.5); // Shuffle the array
      const nextBatch = shuffledRhymes.slice(0, 6); // Take the first 6 items
      setCurrentBatch(nextBatch);
    }
  };

  return (
    <div className="rhyme-suggestions-container" aria-label="Rhyme Suggestions">
      {loading ? (
        <p className="loading-message" role="status">
          Loading rhymes...
        </p>
      ) : error ? (
        <div className="error-container">
          <p className="error-message">{error}</p>
          <button onClick={() => fetchRhymes(word)} className="retry-button">
            Retry
          </button>
        </div>
      ) : (
        uniqueRhymes.length > 0 && (
          <div>
            <h3>
              Rhymes for: <strong>{word}</strong> (
              {language === 'en'
                ? 'English'
                : language === 'it'
                ? 'Italian'
                : language === 'es'
                ? 'Spanish'
                : language === 'fr'
                ? 'French'
                : language === 'de'
                ? 'German'
                : language === 'ru'
                ? 'Russian'
                : ''})
            </h3>
            <div className="rhymes-container" role="listbox" aria-label="List of rhymes">
              {currentBatch.map((rhyme, index) => (
                <div
                  key={index}
                  onClick={() => onSelectRhyme(rhyme.word)}
                  className="rhyme-item"
                  role="option"
                  tabIndex={0}
                  onKeyDown={(e) => e.key === 'Enter' && onSelectRhyme(rhyme.word)}
                >
                  {rhyme.word}
                </div>
              ))}
            </div>
            <div className="shuffle-controls">
              <button onClick={handleShuffle} disabled={loading}>
                Shuffle
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default React.memo(RhymeSuggestions);