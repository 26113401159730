import * as React from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, MenuItem, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';


import { getAuth } from 'firebase/auth';
import Logo from '../Logo/Logo';
import './NavBar.css'; // Import the CSS file

export default function NavBar() {
    const auth = getAuth(); // Stato per l'utente
    const user = auth.currentUser;
    const [anchorEl, setAnchorEl] = React.useState(null); // State to manage the menu anchor element
    const navigate = useNavigate(); // Hook for navigation
    const location = useLocation(); // Hook to get the current location



    // Handle opening the menu
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget); // Set the anchor element for the menu
    };

    // Handle closing the menu
    const handleClose = () => {
        setAnchorEl(null); // Clear the anchor element to close the menu
    };

    // Redirect to a specified path and close the menu
    const handleRedirect = (path) => {
        navigate(path); // Navigate to the specified path
        handleClose(); // Close the menu after redirection
    };

    // Handle user logout
    const handleLogout = () => {
        console.log("Logout");
        auth.signOut();
    };

    return (
        <Box sx={{ flexGrow: 1 }} className="navbar">
            <AppBar position="static" className="navbar">
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    {/* Home Button with Logo */}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="home"
                        onClick={() => handleRedirect('/')}
                        className="navbar-logo"
                    >
                        <Logo />
                    </IconButton>



                    {/* User Menu */}
                    <div className="navbar-menu">
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            className="navbar-account-icon"
                        >
                            <AccountCircle />
                        </IconButton>

                        {/* Dropdown Menu */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{
                                className: 'navbar-menu-list', // Apply custom styles
                            }}
                        >
                            {user ? (
                                <>
                                    {/* Authenticated User Options */}
                                    <MenuItem
                                        onClick={() => handleRedirect('/')}
                                        className="navbar-menu-item"
                                    >
                                        Bars Maker Studio "Coming Soon"
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleRedirect('/dashboard')}
                                        className="navbar-menu-item"
                                    >
                                        Dashboard
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleRedirect('/user-profile')}
                                        className="navbar-menu-item"
                                    >
                                        Profile
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleRedirect('/my-account')}
                                        className="navbar-menu-item"
                                    >
                                        My Account
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleLogout}
                                        className="navbar-menu-item"
                                    >
                                        Logout
                                    </MenuItem>
                                </>
                            ) : (
                                <>
                                    {/* Unauthenticated User Options */}
                                    <MenuItem
                                        onClick={() => handleRedirect('/login')}
                                        className="navbar-menu-item"
                                    >
                                        Login
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleRedirect('/register')}
                                        className="navbar-menu-item"
                                    >
                                        Register
                                    </MenuItem>
                                </>
                            )}
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}