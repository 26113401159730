import app from "./components/Firebase";
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import BarsManager from './components/BarsManager';
import Footer from './components/Footer';
import IubendaCookieConsent from './components/IubendaCookieConsent';
import './App.css';
import AudioUploaderPlayer from './components/AudioUploaderPlayer';
import Service from './Service';
import PrivacyPolicy from './TermCond';
import Partners from './Partners';
import Freestyle from './Freestyle';
import NavBar from './components/UI/Navbar/NavBar';
import CustomCookieConsent from './CustomCookieConsent';
import Login from './components/UI/Auth/Login';
import Register from './components/UI/Auth/Register';
import UserProfile from './components/pages/UserProfile';
import MyAccount from './components/pages/MyAccount';
import Dashboard from './components/pages/DashboardPage';


function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={
            <>
              <CustomCookieConsent />
              <Header />
              <BarsManager />
              <AudioUploaderPlayer />
              <Footer />
              <IubendaCookieConsent />
            </>
          } />
          <Route path="/service" element={<Service />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/freestyle" element={<Freestyle />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/dashboard" element={<Dashboard />} /> {/* Add this line for the Dashboard route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
