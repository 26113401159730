import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container, Paper } from '@mui/material';
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';

const auth = getAuth();
function Register() {
    const database = getDatabase()
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault();

        // Controllo password
        if (password !== confirmPassword) {
            setError("Passwords don't match.");
            return;
        }

        try {
            // Crea l'utente con email e password
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            // Aggiorna il profilo dell'utente (displayName)
            await updateProfile(userCredential.user, {
                displayName: username,
            });

            // Salva i dati utente su Realtime Database
            // Esempio: /users/uid/ con email e username
            await set(ref(database, 'users/' + userCredential.user.uid), {
                email: email,
                username: username,
                createdAt: new Date().toISOString()
            });

            // Reindirizza dopo la registrazione
            navigate('/');
        } catch (error) {
            console.error(error);
            setError('Failed to create an account: ' + error.message);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                <form onSubmit={handleRegister} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '1rem' }}
                    >
                        Register
                    </Button>
                    {error && (
                        <Typography color="error" style={{ marginTop: '1rem' }}>
                            {error}
                        </Typography>
                    )}
                </form>
            </Paper>
        </Container>
    );
}

export default Register;