import React, { useState, useEffect, useRef } from 'react';
import { jsPDF } from 'jspdf';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar
} from '@mui/material';
import RhymeSuggestions from '../components/RhymeSuggestions';
import './BarsManager.css';

// Componente SaveDialog definito inline (senza creare un file separato)
const SaveDialog = ({ open, onClose, onSave }) => {
  const [title, setTitle] = useState('');

  const handleSave = () => {
    if (title.trim() !== '') {
      onSave(title);
      setTitle('');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Salva le tue Barre</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Titolo del salvataggio"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annulla</Button>
        <Button onClick={handleSave} color="primary">
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function BarsManager() {
  /* ----------------------------- STATE ----------------------------- */
  const [bars, setBars] = useState([]);
  const [newBar, setNewBar] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [showRhymes, setShowRhymes] = useState(false);
  const [currentTrack, setCurrentTrack] = useState({
    youtube: '',
    soundCloud: '',
    uploaded: ''
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [saves, setSaves] = useState(() => {
    const savedSaves = localStorage.getItem('barsSaves');
    return savedSaves ? JSON.parse(savedSaves) : [];
  });
  const [language, setLanguage] = useState('en');
  const [longPressTimer, setLongPressTimer] = useState(null);

  // Metronomo
  const [isMetronomePlaying, setIsMetronomePlaying] = useState(false);
  const [bpm, setBpm] = useState(60);
  const [metronomeSound, setMetronomeSound] = useState('classic');
  const [metronomePitch, setMetronomePitch] = useState(880);
  const audioContextRef = useRef(null);
  const oscillatorRef = useRef(null);

  // Modalità Studio/Live
  const [viewMode, setViewMode] = useState('studio'); // 'studio' oppure 'live'

  // Stato per indicare se è stato caricato un salvataggio
  const [isSavedBarLoaded, setIsSavedBarLoaded] = useState(false);

  // Temi personalizzabili
  const [theme, setTheme] = useState('light');
  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  // Stato per il dialog del salvataggio
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  // Stato per notifiche (Snackbar)
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  /* -------------------------- EFFECTS ------------------------------ */
  // Sincronizza i salvataggi nel localStorage
  useEffect(() => {
    localStorage.setItem('barsSaves', JSON.stringify(saves));
  }, [saves]);

  /* ------------------------- FUNZIONI UTILI ------------------------ */

  // Aggiunge una nuova barra
  const addBar = () => {
    if (newBar.trim() !== '') {
      setBars([...bars, newBar.trim()]);
      setNewBar('');
      setShowRhymes(false);
      setSnackbar({ open: true, message: 'Barra aggiunta!', severity: 'success' });
    }
  };

  // Inizia la modifica di una barra (trigger via doppio click o long press)
  const startEditing = (index) => {
    setEditingIndex(index);
    setEditingText(bars[index]);
  };

  // Salva la modifica di una barra
  const saveEdit = (index) => {
    const updatedBars = bars.map((bar, i) => (i === index ? editingText.trim() : bar));
    setBars(updatedBars);
    setEditingIndex(null);
    setEditingText('');
  };

  // Gestione del salvataggio (utilizza il dialog interno)
  const handleSaveBars = (title) => {
    if (saves.length >= 10) {
      setSnackbar({ open: true, message: "Hai raggiunto il numero massimo di salvataggi.", severity: 'warning' });
      return;
    }
    const newSave = {
      title,
      currentTrack,
      bars: bars.join("\n"),
      dateTime: new Date().toLocaleString(),
    };
    setSaves([...saves, newSave]);
    setSnackbar({ open: true, message: 'Barre salvate con successo!', severity: 'success' });
    setSaveDialogOpen(false);
  };

  // Carica un salvataggio (sovrascrivendo le barre attuali)
  const handleLoadSave = (save) => {
    if (window.confirm("Caricare questo salvataggio sovrascriverà le barre correnti. Sei sicuro?")) {
      setBars(save.bars.split("\n"));
      setCurrentTrack(save.currentTrack);
      setIsSavedBarLoaded(true);
      setSnackbar({ open: true, message: 'Salvataggio caricato.', severity: 'info' });
    }
  };

  // Elimina un salvataggio
  const handleDeleteSave = (indexToDelete) => {
    if (window.confirm("Sei sicuro di voler eliminare questo salvataggio?")) {
      const updatedSaves = saves.filter((_, index) => index !== indexToDelete);
      setSaves(updatedSaves);
      setSnackbar({ open: true, message: 'Salvataggio eliminato.', severity: 'info' });
    }
  };

  // Esporta il salvataggio in vari formati
  const handleExportSave = (save, format) => {
    const content = `Title: ${save.title}\n\n${save.bars}`;
    if (format === 'txt') {
      const blob = new Blob([content], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${save.title}.txt`;
      a.click();
      URL.revokeObjectURL(url);
    } else if (format === 'pdf') {
      const doc = new jsPDF();
      doc.setFontSize(12);
      doc.text(content, 10, 10);
      doc.save(`${save.title}.pdf`);
    } else if (format === 'json') {
      const blob = new Blob([JSON.stringify(save, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${save.title}.json`;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  // Cancella tutte le barre attuali
  const handleClearBars = () => {
    if (window.confirm("Sei sicuro di voler cancellare tutte le barre?")) {
      setBars([]);
      setIsSavedBarLoaded(false);
      setSnackbar({ open: true, message: 'Tutte le barre sono state cancellate.', severity: 'info' });
    }
  };

  // Gestione dell'input della barra
  const handleBarInput = (e) => {
    setNewBar(e.target.value);
  };

  // Gestisce il click sul pulsante per richiedere rime
  const handleRhymeButtonClick = () => {
    if (newBar.trim() !== '') {
      const lastWord = newBar.trim().split(" ").pop();
      setCurrentWord(lastWord);
      setShowRhymes(true);
    }
  };

  // Seleziona una rima suggerita
  const handleSelectRhyme = (rhyme) => {
    setNewBar(prev => `${prev} ${rhyme}`);
    setShowRhymes(false);
  };

  // Gestione del long press per dispositivi touch (per avviare la modifica)
  const handleTouchStart = (index) => {
    const timer = setTimeout(() => {
      startEditing(index);
    }, 500);
    setLongPressTimer(timer);
  };

  const handleTouchEnd = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
  };

  // Importa un file di testo e lo divide in barre
  const handleFileImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const importedBars = splitTextIntoBars(text);
        setBars(importedBars);
        setSnackbar({ open: true, message: 'Barre importate!', severity: 'success' });
      };
      reader.readAsText(file);
    }
  };

  // Divide il testo importato in barre, basandosi su una divisione ogni 4 parole
  const splitTextIntoBars = (text) => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const resultBars = lines.flatMap(line => {
      const words = line.split(' ').filter(word => word.trim() !== '');
      const barsFromLine = [];
      let currentBarWords = [];

      words.forEach((word, index) => {
        currentBarWords.push(word);
        if ((index + 1) % 4 === 0) {
          barsFromLine.push(currentBarWords.join(' '));
          currentBarWords = [];
        }
      });
      if (currentBarWords.length > 0) {
        barsFromLine.push(currentBarWords.join(' '));
      }
      return barsFromLine;
    });
    return resultBars;
  };

  // Toggla la modalità view (studio vs live)
  const toggleViewMode = () => {
    setViewMode(prev => (prev === 'studio' ? 'live' : 'studio'));
  };

  // Toggla il dropdown dei salvataggi
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Avvio del metronomo
  const startMetronome = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    const interval = (60 / bpm) * 1000;
    oscillatorRef.current = setInterval(() => {
      playTick();
    }, interval);
    setIsMetronomePlaying(true);
  };

  // Arresto del metronomo
  const stopMetronome = () => {
    clearInterval(oscillatorRef.current);
    setIsMetronomePlaying(false);
  };

  // Riproduce il tick del metronomo
  const playTick = () => {
    const osc = audioContextRef.current.createOscillator();
    osc.frequency.setValueAtTime(metronomePitch, audioContextRef.current.currentTime);
    switch (metronomeSound) {
      case 'wood':
        osc.type = 'square';
        break;
      case 'electronic':
        osc.type = 'sawtooth';
        break;
      case 'triangle':
        osc.type = 'triangle';
        break;
      case 'sineLow':
        osc.type = 'sine';
        break;
      case 'sawtoothHigh':
        osc.type = 'sawtooth';
        break;
      default:
        osc.type = 'sine';
        break;
    }
    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.setValueAtTime(0.3, audioContextRef.current.currentTime);
    osc.connect(gainNode).connect(audioContextRef.current.destination);
    osc.start();
    osc.stop(audioContextRef.current.currentTime + 0.1);
  };

  /* ---------------------------- RENDER ----------------------------- */
  return (
    <Container className={`container ${viewMode === 'live' ? 'live-mode' : 'studio-mode'} theme-${theme}`}>
      {/* Selettore del tema */}
      <Box className="theme-selector" mb={2}>
        <Typography variant="subtitle1">Scegli un tema:</Typography>
        <Select value={theme} onChange={handleThemeChange} size="small">
          <MenuItem value="light">Light Mode</MenuItem>
          <MenuItem value="dark">Dark Mode</MenuItem>
          <MenuItem value="modern1">Modern Gradient 1</MenuItem>
          <MenuItem value="modern2">Modern Gradient 2</MenuItem>
          <MenuItem value="modern3">Modern Gradient 3</MenuItem>
          <MenuItem value="cyber">Cyber Mode</MenuItem>
        </Select>
        <input
              type="file"
              id="file-import"
              accept=".txt"
              style={{ display: 'none' }}
              onChange={handleFileImport}
            />
            <label htmlFor="file-import" className="import-button">
              <Button variant="outlined" component="span">
                📙
              </Button>
            </label>
      </Box>

      {/* Pulsante per passare alla modalità Live (se è stato caricato un salvataggio) */}
      {isSavedBarLoaded && (
        <Button onClick={toggleViewMode} variant="outlined" className="toggle-view-btn" sx={{ mb: 2 }}>
          {viewMode === 'studio' ? 'Passa a Live Mode' : 'Torna in Studio Mode'}
        </Button>
      )}

      <Typography variant="h4" gutterBottom>
        Write your Bars
      </Typography>

      {viewMode === 'studio' && (
        <>
          {/* Selettore della lingua */}
          <Box className="language-selector" mb={2}>
            <Typography variant="subtitle1">Language:</Typography>
            <Select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              size="small"
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="it">Italian</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
              <MenuItem value="fr">French</MenuItem>
              <MenuItem value="de">German</MenuItem>
              <MenuItem value="ru">Russian</MenuItem>
            </Select>
          </Box>

          {/* Area di input per le barre */}
          <Box className="input-container" mb={2} display="flex" gap={1}>
            <TextField
              label="Write your Bars here..."
              value={newBar}
              onChange={handleBarInput}
              onKeyPress={(e) => e.key === 'Enter' && addBar()}
              inputProps={{ maxLength: 300 }}
              fullWidth
            />
            <Button variant="contained" onClick={addBar}>
              🔫
            </Button>
            <Button variant="outlined" onClick={handleRhymeButtonClick} className="rhyme-suggestion-button">
              📖
            </Button>
          </Box>

          {/* Suggerimenti per le rime */}
          {showRhymes && (
            <RhymeSuggestions 
              word={currentWord} 
              onSelectRhyme={handleSelectRhyme} 
              language={language} 
            />
          )}

          {/* Lista delle barre */}
          <Box mb={2}>
            <Typography variant="h6">Le Tue Barre</Typography>
            <ul className="ulli">
              {bars.map((bar, index) => (
                <li
                  key={index}
                  onDoubleClick={() => startEditing(index)}
                  onTouchStart={() => handleTouchStart(index)}
                  onTouchEnd={handleTouchEnd}
                  className={`bar-container ${editingIndex === index ? 'editing' : ''}`}
                >
                  {editingIndex === index ? (
                    <TextField
                      value={editingText}
                      onChange={(e) => setEditingText(e.target.value)}
                      onBlur={() => saveEdit(index)}
                      onKeyPress={(e) => e.key === 'Enter' && saveEdit(index)}
                      autoFocus
                      size="small"
                      fullWidth
                      className="editing-input"
                    />
                  ) : (
                    <span>{bar}</span>
                  )}
                </li>
              ))}
            </ul>
          </Box>

          {/* Pulsanti per salvare/cancellare barre */}
          <Box className="save-bars-button-container" mb={2} display="flex" gap={2}>
            <Button variant="contained" onClick={() => setSaveDialogOpen(true)}>
              Save
            </Button>
            <Button variant="outlined" color="error" onClick={handleClearBars} className="clear-bars-button">
              Clear
            </Button>
          </Box>

          {/* Sezione dei salvataggi */}
          <Box className="your-bars-section" mb={2}>
            <Button variant="outlined" onClick={toggleDropdown} className="dropdown-toggle">
              Your Bars
            </Button>
            {dropdownOpen && (
              <Box className="saves-grid">
                {saves.map((save, index) => (
                  <Box key={index} className="save-card">
                    <Typography variant="h6">{save.title}</Typography>
                    <Typography variant="caption">Saved on: {save.dateTime}</Typography>
                    <Box className="actions" mt={1} display="flex" gap={1}>
                      <Button variant="contained" onClick={() => handleLoadSave(save)}>
                        Load
                      </Button>
                      <Button variant="outlined" onClick={() => handleExportSave(save, 'txt')} className="export-btn">
                        Export TXT
                      </Button>
                      <Button variant="outlined" onClick={() => handleExportSave(save, 'pdf')} className="export-btn">
                        Export PDF
                      </Button>
                      <Button variant="outlined" color="error" onClick={() => handleDeleteSave(index)} className="delete-btn">
                        Delete
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          {/* Sezione Metronomo */}
          <Box className="metronome" mb={2}>
            <Typography variant="h5" gutterBottom>Metronome</Typography>
            <Box mb={1}>
              <TextField
                label="BPM"
                type="number"
                value={bpm}
                onChange={(e) => setBpm(Number(e.target.value))}
                InputProps={{ inputProps: { min: 40, max: 200 } }}
                size="small"
              />
            </Box>
            <Box mb={1}>
              <Typography variant="subtitle1">Sound:</Typography>
              <Select
                value={metronomeSound}
                onChange={(e) => setMetronomeSound(e.target.value)}
                size="small"
              >
                <MenuItem value="classic">Classic (sine)</MenuItem>
                <MenuItem value="wood">Wood (square)</MenuItem>
                <MenuItem value="electronic">Electronic (sawtooth)</MenuItem>
                <MenuItem value="triangle">Triangle</MenuItem>
                <MenuItem value="sineLow">Sine Low</MenuItem>
                <MenuItem value="sawtoothHigh">Sawtooth High</MenuItem>
              </Select>
            </Box>
            <Box mb={1}>
              <TextField
                label="Pitch (Hz)"
                type="number"
                value={metronomePitch}
                onChange={(e) => setMetronomePitch(Number(e.target.value))}
                InputProps={{ inputProps: { min: 100, max: 2000, step: 10 } }}
                size="small"
              />
            </Box>
            <Button variant="contained" onClick={isMetronomePlaying ? stopMetronome : startMetronome}>
              {isMetronomePlaying ? 'Stop' : 'Start'}
            </Button>
          </Box>
        </>
      )}

      {/* Modalità Live: mostra solo le barre in formato testo */}
      {viewMode === 'live' && (
        <Box className="live-text">
          <ul className="ulli live-bars">
            {bars.map((bar, index) => (
              <li key={index} className="bar-container-live">
                {bar}
              </li>
            ))}
          </ul>
        </Box>
      )}

      {/* Dialog per il salvataggio delle barre */}
      <SaveDialog
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onSave={handleSaveBars}
      />

      {/* Snackbar per notifiche */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default BarsManager;
