// ✅ PostForm.jsx aggiornato con reset colore
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  getStorage, ref as storageRef, uploadBytes, getDownloadURL
} from 'firebase/storage';
import { getDatabase, ref as dbRef, push } from 'firebase/database';
import {
  Button, TextField, Box, Typography, CircularProgress, Tooltip
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import './PostForm.css';

const vocalColors = {
  Main: { color: '#1E3A8A', text: '#FFFFFF' },
  Doubles: { color: '#9333EA', text: '#FFFFFF' },
  Adlibs: { color: '#059669', text: '#FFFFFF' },
  Chorus: { color: '#F59E0B', text: '#000000' }
};

const defaultColor = { color: '#FFFFFF', text: '#000000' };

const auth = getAuth();

function PostForm() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [newBar, setNewBar] = useState('');
  const [bars, setBars] = useState([]);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [barStyle, setBarStyle] = useState(vocalColors.Main);
  const [uploading, setUploading] = useState(false);

  const handleAddBar = () => {
    if (newBar.trim()) {
      setBars((prev) => [...prev, { text: newBar.trim(), color: barStyle.color, textColor: barStyle.text }]);
      setNewBar('');
    }
  };

  const handleUpload = async () => {
    if (!user) return navigate('/login');

    setUploading(true);
    const db = getDatabase();
    const postRef = dbRef(db, `users/${user.uid}/posts`);

    let fileURL = '';
    if (file) {
      const storage = getStorage();
      const refPath = `uploads/${user.uid}/${file.name}_${Date.now()}`;
      const fileRef = storageRef(storage, refPath);
      await uploadBytes(fileRef, file);
      fileURL = await getDownloadURL(fileRef);
    }

    await push(postRef, {
      createdAt: new Date().toISOString(),
      bars,
      fileURL,
      fileType: file?.type || '',
      user: {
        displayName: user.displayName || 'Anonymous',
        photoURL: user.photoURL || '',
        uid: user.uid,
      },
      reactions: [],
      comments: [],
    });

    setBars([]);
    setNewBar('');
    setFile(null);
    setFilePreview(null);
    setUploading(false);
  };

  return (
    <Box className="post-form-container">
      <Typography variant="h5" gutterBottom>
        Crea un nuovo post
      </Typography>

      <TextField
        label="Scrivi una barra"
        value={newBar}
        onChange={(e) => setNewBar(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleAddBar()}
        fullWidth
        multiline
        rows={2}
        sx={{ mb: 2 }}
      />

      <Box className="emotional-colors">
        {Object.entries(vocalColors).map(([label, style]) => (
          <Tooltip title={label} key={label}>
            <Box
              className="color-option"
              style={{ backgroundColor: style.color }}
              onClick={() => setBarStyle(style)}
            />
          </Tooltip>
        ))}
        <Tooltip title="Reset colore">
          <Box
            className="color-option"
            style={{ backgroundColor: defaultColor.color, border: '2px dashed #aaa' }}
            onClick={() => setBarStyle(defaultColor)}
          />
        </Tooltip>
      </Box>

      <Button onClick={handleAddBar} variant="outlined" sx={{ mt: 2 }}>
        Aggiungi barra
      </Button>

      <Box className="bars-preview">
        {bars.map((bar, i) => (
          <Box
            key={i}
            className="preview-bar"
            style={{ backgroundColor: bar.color, color: bar.textColor }}
          >
            {bar.text}
          </Box>
        ))}
      </Box>

      <Box className="file-preview">
        <input
          type="file"
          accept="audio/*,image/*,video/*"
          id="upload"
          style={{ display: 'none' }}
          onChange={(e) => {
            const f = e.target.files[0];
            if (!f) return;
            setFile(f);
            setFilePreview(URL.createObjectURL(f));
          }}
        />
        <label htmlFor="upload">
          <Button
            variant="outlined"
            component="span"
            startIcon={<UploadIcon />}
            sx={{ mt: 2 }}
          >
            Carica file
          </Button>
        </label>

        {filePreview && (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            {file?.type?.startsWith('image/') && (
              <img src={filePreview} alt="preview" className="preview-image" />
            )}
            {file?.type?.startsWith('video/') && (
              <video controls className="preview-video">
                <source src={filePreview} type={file.type} />
              </video>
            )}
            {file?.type?.startsWith('audio/') && (
              <audio controls className="preview-audio">
                <source src={filePreview} type={file.type} />
              </audio>
            )}
          </Box>
        )}
      </Box>

      <Box className="buttons-container">
        <Button
          onClick={handleUpload}
          variant="contained"
          disabled={uploading}
          startIcon={uploading ? <CircularProgress size={20} /> : null}
        >
          {uploading ? 'Caricamento...' : 'Pubblica'}
        </Button>

        <Button
          onClick={() => {
            setBars([]);
            setNewBar('');
            setFile(null);
            setFilePreview(null);
          }}
          variant="outlined"
          startIcon={<DeleteIcon />}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
}

export default PostForm;