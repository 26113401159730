import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { Environment } from '@react-three/drei';

function RotatingPrism() {
  const prismRef = useRef();

  useFrame(() => {
    if (prismRef.current) {
      prismRef.current.rotation.y += 0.01;
      prismRef.current.rotation.x += 0.005;
    }
  });

  // Definizione della geometria del prisma
  const size = 4;
  const height = 3;
  const shape = new THREE.Shape();

  const a = size / 2;
  const h = (Math.sqrt(3) * size) / 2;

  // Triangolo equilatero nel piano XY
  shape.moveTo(-a, -h / 3);
  shape.lineTo(a, -h / 3);
  shape.lineTo(0, (2 * h) / 3);
  shape.lineTo(-a, -h / 3);

  const extrudeSettings = {
    steps: 1,
    depth: height,
    bevelEnabled: true,
    bevelThickness: 0.1,
    bevelSize: 0.1,
    bevelSegments: 2,
  };

  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

  return (
    <mesh ref={prismRef} geometry={geometry}>
      <meshPhysicalMaterial
        color="#ffffff"
        metalness={0.5}
        roughness={0}
        clearcoat={1}
        clearcoatRoughness={0}
        reflectivity={1}
        transmission={1}
        thickness={2}
        envMapIntensity={1}
      />
    </mesh>
  );
}

function CreativeObject3D() {
  return (
    <div className="creative-object-3d">
      <Canvas>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} intensity={2} />
        <RotatingPrism />
        <Environment preset="sunset" />
        <EffectComposer>
          <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} intensity={1.5} />
        </EffectComposer>
      </Canvas>
    </div>
  );
}

export default CreativeObject3D;
