import React, { useState, useEffect } from 'react';
import EmailModal from './EmailModal'; // Assumi che questo sia il tuo componente modal per l'email

function Footer() {
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);

  // Funzione per gestire il click sull'email
  const handleEmailClick = (e) => {
    e.preventDefault();
    setEmailModalOpen(true);
  };

  // UseEffect per caricare dinamicamente gli script di Iubenda
  useEffect(() => {
    // Configurazione Iubenda
    const iubendaConfigScript = document.createElement('script');
    iubendaConfigScript.type = 'text/javascript';
    iubendaConfigScript.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {"siteId":3505472,"cookiePolicyId":11078590,"lang":"it","storage":{"useSiteId":true}};
    `;
    document.body.appendChild(iubendaConfigScript);

    // Script di autoblocking
    const autoblockingScript = document.createElement('script');
    autoblockingScript.src = 'https://cs.iubenda.com/autoblocking/3505472.js';
    autoblockingScript.async = true;
    document.body.appendChild(autoblockingScript);

    // Script principale di Iubenda
    const iubendaCsScript = document.createElement('script');
    iubendaCsScript.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
    iubendaCsScript.charset = 'UTF-8';
    iubendaCsScript.async = true;
    document.body.appendChild(iubendaCsScript);

    // Pulizia: rimuovi gli script al dismount del componente
    return () => {
      document.body.removeChild(iubendaConfigScript);
      document.body.removeChild(autoblockingScript);
      document.body.removeChild(iubendaCsScript);
    };
  }, []);

  return (
    <footer>
      <div className="footer-content">
        <p>&copy; 2023-2025 Bars Maker. All rights reserved.</p>
        <p>Contact us: <a href="#!" onClick={handleEmailClick}>barsmaker@gmail.com</a></p>
        <p>Designed by FurbsLoops</p>
      </div>
      <div className="privacycontents">
        <div className="privacyp">
          <a
            href="https://www.iubenda.com/privacy-policy/11078590"
            className="iubenda-white iubenda-embed"
            title="Privacy Policy"
          >
            Privacy Policy
          </a>
        </div>
        <div className="cookiep">
          <a
            href="https://www.iubenda.com/privacy-policy/11078590/cookie-policy"
            className="iubenda-white iubenda-embed"
            title="Cookie Policy"
          >
            Cookie Policy
          </a>
        </div>
        <div className="termsp">
          <a
            href="https://www.iubenda.com/termini-e-condizioni/11078590"
            className="iubenda-white iubenda-embed"
            title="Termini e Condizioni"
          >
            Termini e Condizioni
          </a>
        </div>
      </div>
      {isEmailModalOpen && <EmailModal onClose={() => setEmailModalOpen(false)} />}
    </footer>
  );
}

export default Footer;