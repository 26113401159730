import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, onValue } from 'firebase/database'; // <== usiamo Realtime Database
import { db } from '../Firebase';                // <== importa il tuo file di config con "export const db = getDatabase(...)"
import {
    Avatar,
    Typography,
    Container,
    Paper,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

function MyAccount() {
    const auth = getAuth();
    const navigate = useNavigate();

    // Stato per i dati utente (provenienti dalla RTDB o da Firebase Auth come fallback)
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (!currentUser) {
                // Se l’utente NON è loggato, rimandiamo al login
                navigate('/login');
            } else {
                // Se l’utente è loggato, carichiamo i dati da RTDB
                const userRef = ref(db, `users/${currentUser.uid}`);
                onValue(userRef, (snapshot) => {
                    if (snapshot.exists()) {
                        // Dati presenti in RTDB
                        const rtdbData = snapshot.val();
                        setUserData({
                            displayName: rtdbData.displayName || currentUser.displayName || 'Not set',
                            email: rtdbData.email || currentUser.email,
                            photoURL: rtdbData.photoURL || currentUser.photoURL,
                            // Nel caso in cui nel DB non salvi la creationTime, usiamo quella di Auth
                            creationTime: rtdbData.creationTime || currentUser.metadata.creationTime
                        });
                    } else {
                        // Se non hai salvato nulla in RTDB, usiamo i dati di Firebase Auth come fallback
                        setUserData({
                            displayName: currentUser.displayName || 'Not set',
                            email: currentUser.email,
                            photoURL: currentUser.photoURL,
                            creationTime: currentUser.metadata.creationTime
                        });
                    }
                });
            }
        });

        // Cleanup: disiscrizione dall’observer
        return () => unsubscribe();
    }, [auth, navigate]);

    // Se userData è null, mostriamo un caricamento
    if (!userData) {
        return <Typography>Loading account information...</Typography>;
    }

    // Format creationTime if it's a string/Date
    let memberSince = 'Unknown date';
    if (userData.creationTime) {
        // Se userData.creationTime è una stringa ISO,
        // converti in Date per poi formattare
        const dateObj = new Date(userData.creationTime);
        memberSince = dateObj.toLocaleDateString();
    }

    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', marginBottom: '20px' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    My Account
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Avatar
                            alt={userData.displayName}
                            src={userData.photoURL || '/static/images/avatar/default.png'}
                            style={{ width: '150px', height: '150px' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccountCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Display Name"
                                    secondary={userData.displayName}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccountCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Email"
                                    secondary={userData.email}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccountCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Member Since"
                                    secondary={memberSince}
                                />
                            </ListItem>

                            {/* Aggiungi altre informazioni se vuoi, per es. userData.phoneNumber, ecc. */}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default MyAccount;
