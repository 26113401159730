// PostList.js
import React, { useState, useEffect } from 'react';
// Import funzioni Realtime DB
import { ref, onValue, remove, update, set, off } from 'firebase/database';
import { db } from './Firebase';

import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import './PostList.css';

import {
  Avatar,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Select,
  MenuItem
} from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';

const emotionalColors = {
  Anger: { color: '#FF0000', description: 'Red = Anger' },
  Security: { color: '#FFD700', description: 'Yellow = Security' },
  Sadness: { color: '#0000FF', description: 'Blue = Sadness' },
  Questioning: { color: '#00FF00', description: 'Green = Questioning' },
  Hate: { color: '#8B0000', description: 'Bordeaux = Hate' },
  Calm: { color: '#00CED1', description: 'Turquoise = Calm' },
  Double: { color: '#800080', description: 'Purple = Double' },
};

const splitTextIntoBars = (text, maxLength = 40) => {
  const words = text.split(' ');
  const bars = [];
  let currentBar = '';

  words.forEach((word) => {
    if ((currentBar + word).length > maxLength) {
      bars.push(currentBar.trim());
      currentBar = word + ' ';
    } else {
      currentBar += word + ' ';
    }
  });

  if (currentBar.trim() !== '') {
    bars.push(currentBar.trim());
  }
  return bars;
};

// Funzione per calcolare quante reazioni di un certo tipo ci sono
const getReactionCount = (reactions, type) => {
  return reactions.filter((r) => r.type === type).length;
};


// Controllo se è presente nella lista dei post un post creato da me dove ho la possibilità di cancellarlo
const handleDeleteMyPost = () => {

}

function PostList() {
  const auth = getAuth();
  const [user] = useAuthState(auth);

  const [posts, setPosts] = useState([]);
  const [myPosts, setMyPosts] = useState([]);
  const [newComment, setNewComment] = useState({});
  const [expandedVideo, setExpandedVideo] = useState(null);
  const [showLegend, setShowLegend] = useState(false);
  const [sortBy, setSortBy] = useState('timestamp');
  const [filterBy, setFilterBy] = useState('all');
  const [editingPost, setEditingPost] = useState(null);

  useEffect(() => {
    const usersRef = ref(db, 'users');

    const unsubscribe = onValue(usersRef, (snapshot) => {
      if (!snapshot.exists()) {
        setPosts([]);
        setMyPosts([]);
        return;
      }

      const usersData = snapshot.val();
      const loadedPosts = [];

      Object.entries(usersData).forEach(([userId, userData]) => {
        if (userData.posts) {
          Object.entries(userData.posts).forEach(([postId, postData]) => {
            loadedPosts.push({
              id: postId,
              userId: userId,  // <-- UID dell'utente autore
              user: userData.email || userData.username || 'Anonymous',
              userPhoto: userData.photoURL || '',
              timestamp: postData.createdAt
                ? new Date(postData.createdAt).toLocaleString()
                : 'Data non disponibile',
              bars: postData.bars || [],
              fileURL: postData.fileURL || '',
              fileType: postData.fileType || '',
              reactions: postData.reactions || [],
              comments: postData.comments || []
            });
          });
        }
      });

      // Ordina i post in base al timestamp (decrescente)
      loadedPosts.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp);
      });

      // Salva tutti i post nello stato generale
      setPosts(loadedPosts);

      // Se l’utente è loggato, filtra i post creati da lui
      if (user) {
        const postsOfCurrentUser = loadedPosts.filter(
          (post) => post.userId === user.uid
        );
        setMyPosts(postsOfCurrentUser);
      } else {
        // Se non c’è utente loggato, la lista dei miei post è vuota
        setMyPosts([]);
      }
    });

    return () => off(usersRef);
  }, [user]); // <-- Assicurati di aggiungere `user` come dipendenza


  // === Funzione: eliminazione post dal DB ===
  const handleDeletePost = async (postId, userId) => {
    if (!user) return; // opzionale: controlla se l'utente è loggato
    const postRef = ref(db, `users/${userId}/posts/${postId}`);
    await remove(postRef);
  };

  // === Funzione: modifica post (es. aggiornare bars) ===
  const handleEditPost = async (postId, userId, newBars) => {
    if (!user) return;
    const postRef = ref(db, `users/${userId}/posts/${postId}`);
    await update(postRef, { bars: newBars });
    setEditingPost(null); // nascondere editor dopo la modifica
  };

  // === Funzione: aggiunta/commento al post (salva array di commenti) ===
  const handleComment = async (postId, userId, currentComments, commentText) => {
    if (!user) return;
    const commentsRef = ref(db, `users/${userId}/posts/${postId}/comments`);

    const newCommentObj = {
      text: commentText,
      user: user.displayName || 'Anon',
      userPhoto: user.photoURL || ''
    };

    // Se i commenti sono un array in RTDB, qui li rimpiazziamo
    const updatedComments = currentComments
      ? [...currentComments, newCommentObj]
      : [newCommentObj];

    // Salviamo l'intero array come nuovo valore di "comments"
    await set(commentsRef, updatedComments);
  };

  // === Funzione: reazioni (like, dislike, super) ===
  const handleReaction = async (postId, userId, reactionType) => {
    if (!user) return;

    // Trova il post in state
    const post = posts.find((p) => p.id === postId && p.userId === userId);
    if (!post) return;

    const existingReaction = post.reactions.find(
      (r) => r.uid === user.uid
    );

    let updatedReactions = [];

    // Se c'è già una reazione dello stesso utente
    if (existingReaction) {
      // Se la reazione è la stessa, la rimuoviamo (un-toggle)
      if (existingReaction.type === reactionType) {
        updatedReactions = post.reactions.filter(
          (r) => r.uid !== user.uid
        );
      } else {
        // Altrimenti la aggiorniamo con il nuovo tipo
        updatedReactions = post.reactions.map((r) =>
          r.uid === user.uid ? { ...r, type: reactionType } : r
        );
      }
    } else {
      // Non c'era reazione dell'utente => la aggiungiamo
      updatedReactions = [
        ...post.reactions,
        { uid: user.uid, type: reactionType }
      ];
    }

    // Salviamo le reazioni aggiornate nel DB
    const reactionsRef = ref(db, `users/${userId}/posts/${postId}/reactions`);
    await set(reactionsRef, updatedReactions);
  };

  // === Toggle per l'allargare/ridurre il video ===
  const toggleVideoSize = (id) => {
    setExpandedVideo(expandedVideo === id ? null : id);
  };

  // === Toggle legenda colori ===
  const toggleLegend = () => {
    setShowLegend(!showLegend);
  };

  // === Ordinamento in base a sortBy (timestamp, reactions, comments) ===
  const sortedPosts = [...posts].sort((a, b) => {
    if (sortBy === 'timestamp') {
      return new Date(b.timestamp) - new Date(a.timestamp);
    } else if (sortBy === 'reactions') {
      return b.reactions.length - a.reactions.length;
    } else if (sortBy === 'comments') {
      // Se i commenti sono un array, usiamo la lunghezza
      return b.comments.length - a.comments.length;
    }
    return 0;
  });

  // === Filtro (all, image, video, audio) ===
  const filteredPosts = sortedPosts.filter((post) => {
    if (filterBy === 'all') return true;
    if (filterBy === 'image') return post.fileType.startsWith('image/');
    if (filterBy === 'video') return post.fileType.startsWith('video/');
    if (filterBy === 'audio') return post.fileType.startsWith('audio/');
    return true;
  });

  return (
    <Box className="post-list">
      {/* Filtri e ordinamento */}
      <Box className="filters-container">
        <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          <MenuItem value="timestamp">Most Recent</MenuItem>
          <MenuItem value="reactions">Most Reactions</MenuItem>
          <MenuItem value="comments">Most Comments</MenuItem>
        </Select>
        <Select value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
          <MenuItem value="all">All Posts</MenuItem>
          <MenuItem value="image">Images</MenuItem>
          <MenuItem value="video">Videos</MenuItem>
          <MenuItem value="audio">Audio</MenuItem>
        </Select>
      </Box>

      {filteredPosts.map((post) => (
        <Box key={post.id} className="post-container">
          <Box className="post-header">
            <Avatar
              src={post.userPhoto}
              alt={post.user}
              className="post-avatar"
            />
            <Typography variant="subtitle1" className="post-user">
              {post.user}
            </Typography>
            <Typography variant="caption" className="post-date">
              {post.timestamp}
            </Typography>

            {/* Controllo se l'utente corrente ha creato il post (logica a tua scelta) */}
            {post.user === user?.displayName && (
              <>
                <IconButton onClick={() => setEditingPost(post)}>
                  <EditIcon />
                </IconButton>
                {/* Passiamo anche l'userId altrimenti non possiamo eliminare dal DB */}
                <IconButton onClick={() => handleDeletePost(post.id, post.userId)}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}


            {user && user.uid === post.userId && (
              <>
                {/* Edit se ti serve */}
                <IconButton onClick={() => setEditingPost(post)}>
                  <EditIcon />
                </IconButton>

                {/* Delete solo se il post è dell'utente corrente */}
                <IconButton onClick={() => handleDeletePost(post.id, post.userId)}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}


            <Tooltip title="Show legend">
              <IconButton onClick={toggleLegend} size="small" className="legend-button">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {showLegend && (
            <Box className="legend">
              {Object.keys(emotionalColors).map((emotion) => (
                <Box
                  key={emotion}
                  className="color-option legend-item"
                  style={{ backgroundColor: emotionalColors[emotion].color }}
                >
                  {emotion}
                </Box>
              ))}
            </Box>
          )}

          {/* Corpo del post */}
          <Box className="post-content">
            {/* Rendering delle "bars" (testo con colore) */}
            <Box className="bars-container">
              {post.bars.map((bar, index) => {
                // bar.text è una stringa, quindi la suddividiamo
                const splittedText = splitTextIntoBars(bar.text);
                // splittedText ora è un array

                return splittedText.map((textLine, subIndex) => (
                  <Box
                    key={`${index}-${subIndex}`}
                    className="bar-container"
                    style={{ backgroundColor: bar.color }}
                  >
                    <Typography variant="body1" className="bar-text">
                      {textLine}
                    </Typography>
                  </Box>
                ));
              })}

            </Box>

            {/* Se c'è un'immagine */}
            {post.fileURL && post.fileType.startsWith('image/') && (
              <Box className="image-container">
                <img src={post.fileURL} alt="uploaded" />
              </Box>
            )}

            {/* Se c'è un video */}
            {post.fileURL && post.fileType.startsWith('video/') && (
              <Box
                className={`video-container ${expandedVideo === post.id ? 'expanded' : ''
                  }`}
              >
                <video
                  controls
                  className={expandedVideo === post.id ? 'enlarged' : ''}
                >
                  <source src={post.fileURL} type={post.fileType} />
                  Your browser does not support the video tag.
                </video>
                <Box className="video-controls">
                  <IconButton onClick={() => toggleVideoSize(post.id)}>
                    {expandedVideo === post.id ? (
                      <FullscreenExitIcon />
                    ) : (
                      <FullscreenIcon />
                    )}
                  </IconButton>
                  {expandedVideo === post.id && (
                    <IconButton onClick={() => setExpandedVideo(null)}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            )}

            {/* Se c'è un audio */}
            {post.fileURL && post.fileType.startsWith('audio/') && (
              <Box className="audio-container">
                <audio controls>
                  <source src={post.fileURL} type={post.fileType} />
                  Your browser does not support the audio element.
                </audio>
              </Box>
            )}
          </Box>

          {/* Sezione "reactions" */}
          <Box className="post-actions">
            <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
              <IconButton
                className="reaction-button"
                onClick={() => handleReaction(post.id, post.userId, 'approve')}
              >
                <ThumbUpIcon
                  className="reaction-icon"
                  style={{ color: '#4CAF50' }}
                />
                <Typography variant="body2" className="reaction-count">
                  {getReactionCount(post.reactions, 'approve')}
                </Typography>
              </IconButton>
              <IconButton
                className="reaction-button"
                onClick={() => handleReaction(post.id, post.userId, 'disapprove')}
              >
                <ThumbDownIcon
                  className="reaction-icon"
                  style={{ color: '#F44336' }}
                />
                <Typography variant="body2" className="reaction-count">
                  {getReactionCount(post.reactions, 'disapprove')}
                </Typography>
              </IconButton>
              <IconButton
                className="reaction-button"
                onClick={() => handleReaction(post.id, post.userId, 'super_approve')}
              >
                <WhatshotIcon
                  className="reaction-icon"
                  style={{ color: '#FF9800' }}
                />
                <Typography variant="body2" className="reaction-count">
                  {getReactionCount(post.reactions, 'super_approve')}
                </Typography>
              </IconButton>
            </Box>
          </Box>


          {/* Sezione commenti */}
          <Box className="post-comments">
            {/* Se i commenti sono un array */}
            {Array.isArray(post.comments) &&
              post.comments.map((comment, index) => (
                <Box key={index} className="comment">
                  <Avatar
                    src={comment.userPhoto}
                    alt={comment.user}
                    className="comment-avatar"
                  />
                  <Box className="comment-content">
                    <Typography variant="subtitle2">{comment.user}</Typography>
                    <Typography variant="body1">{comment.text}</Typography>
                  </Box>
                </Box>
              ))}

            {/* Input per aggiungere un nuovo commento */}
            <Box className="comment-input">
              <TextField
                value={newComment[post.id] || ''}
                onChange={(e) => setNewComment({ ...newComment, [post.id]: e.target.value })}
                placeholder="Write a comment..."
                fullWidth
                variant="outlined"
                size="small"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.target.value.trim() !== '') {
                    // Passiamo l'intero array di commenti per aggiornarlo
                    handleComment(
                      post.id,
                      post.userId,
                      post.comments,
                      e.target.value.trim()
                    );
                    // Puliamo il campo input
                    setNewComment({ ...newComment, [post.id]: '' });
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      ))}

    </Box>
  );
}

export default PostList;
