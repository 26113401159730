// firebase.js
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

// ✅ Configurazione Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAUAK17pUMqqYXhoRGj-y5II0jgR4odVrY",
  authDomain: "barsmaker-8769d.firebaseapp.com",
  databaseURL: "https://barsmaker-8769d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "barsmaker-8769d",
  storageBucket: "barsmaker-8769d.appspot.com",
  messagingSenderId: "155631441271",
  appId: "1:155631441271:web:810d601810244886c9fd72",
  measurementId: "G-SJV889NPSY"
};

// 🚀 Inizializza l'app solo una volta
const app = getApps().length ? getApp() : initializeApp(firebaseConfig);

// 📦 Esportazioni modulari
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { app, auth, db, storage };
