import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth';
import { storage, db } from '../Firebase'; // Assicurati che db e storage puntino alla RTDB e Storage configurati
import {
  ref as storageRefFn,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';

import {
  ref as dbRef,
  onValue,
  update // Per aggiornare i dati in Realtime DB
} from 'firebase/database';

import {
  Avatar,
  Typography,
  Container,
  Paper,
  Grid,
  Box,
  IconButton,
  TextField,
  Button,
  CircularProgress
} from '@mui/material';

import { styled } from '@mui/system';
import { Favorite, Comment, AddPhotoAlternate } from '@mui/icons-material';

const UserProfile = () => {
  const auth = getAuth();
  // Attenzione: in Firebase Auth non esiste `auth.user` ma `auth.currentUser`.
  // user lo ricavi da onAuthStateChanged oppure da useState in base ai tuoi gusti.
  const [currentUser, setCurrentUser] = useState(null);

  const [bars, setBars] = useState([]);
  const [featuredBars, setFeaturedBars] = useState([]);

  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState("");

  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Questa funzione legge tutti i "bars" da Realtime DB,
    // li trasforma in array e filtra in base a userId === currentUser.uid.
    const fetchBarsRTDB = (userUid) => {
      const barsReference = dbRef(db, 'bars');

      onValue(barsReference, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          // Trasformiamo l'oggetto in un array con {id, ...rest}
          const allBars = Object.entries(data).map(([barId, barData]) => ({
            id: barId,
            ...barData
          }));

          // Filtriamo i bars dell'utente corrente
          const userBars = allBars.filter((bar) => bar.userId === userUid);
          setBars(userBars);

          // Filtriamo quelle in evidenza
          const userFeatured = userBars.filter((bar) => bar.isFeatured === true);
          setFeaturedBars(userFeatured);
        } else {
          // Se "bars" non esiste o è vuoto
          setBars([]);
          setFeaturedBars([]);
        }
      });
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      } else {
        // Salviamo lo user in state
        setCurrentUser(user);
        // Carichiamo i "bars" dalla RTDB
        fetchBarsRTDB(user.uid);
      }
    });

    // Cleanup
    return () => unsubscribe();
  }, [auth, navigate]);

  if (!currentUser) {
    return <Typography>Loading profile...</Typography>;
  }

  // === Caricamento e aggiornamento immagine profilo ===
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      try {
        // 1. Carica il file in Firebase Storage
        const imageRef = storageRefFn(storage, `profilePictures/${currentUser.uid}`);
        await uploadBytes(imageRef, file);
        console.log("File caricato con successo in Storage");

        // 2. Ottieni l'URL di download
        const photoURL = await getDownloadURL(imageRef);
        console.log("URL immagine recuperato:", photoURL);

        // 3. Aggiorna l'immagine profilo dell'utente in Firebase Auth
        await updateProfile(currentUser, {
          photoURL: photoURL,
        });
        console.log("Profilo auth aggiornato con la nuova photoURL");

        // 4. Aggiorna il percorso `users/<uid>` nella Realtime DB
        const userRef = dbRef(db, `users/${currentUser.uid}`);
        await update(userRef, { photoURL });
        console.log("Documento utente aggiornato nella RTDB");

      } catch (error) {
        console.error("Errore durante il caricamento dell'immagine del profilo:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  // === Gestione commenti localmente (non salvati in DB) ===
  const handleAddComment = (barId) => {
    if (newComment.trim() !== "") {
      setComments((prev) => ({
        ...prev,
        [barId]: [...(prev[barId] || []), { text: newComment, userName: currentUser.displayName }]
      }));
      setNewComment("");
    }
  };

  // === Styled Components (MUI) ===
  const StyledPaper = styled(Paper)({
    padding: '30px',
    marginTop: '30px',
    backgroundColor: '#141414',
    color: '#e0e0e0',
    borderRadius: '20px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.7)',
  });

  const StyledAvatar = styled(Avatar)({
    width: '160px',
    height: '160px',
    marginBottom: '15px',
    border: '4px solid #00ffcc',
    boxShadow: '0px 0px 15px #00ffcc',
  });

  return (
    <Container component="main" maxWidth="lg">
      {/* Profile Header */}
      <StyledPaper elevation={3}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Box position="relative" display="inline-block">
              <StyledAvatar
                alt={currentUser.displayName || 'User Name'}
                src={currentUser.photoURL || '/static/images/avatar/default.png'}
              />
              {uploading && (
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <CircularProgress />
                </Box>
              )}
              {/* Icona di caricamento immagine */}
              <IconButton
                component="label"
                color="primary"
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                  '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.8)' },
                  color: '#00ffcc',
                  p: 1,
                }}
              >
                <AddPhotoAlternate />
                <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ color: '#00ffcc', textShadow: '0px 0px 10px #00ffcc' }}
            >
              {currentUser.displayName || 'User Name'}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#e0e0e0' }}>
              "BarsMaker is my stage!" <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      {/* Featured Bars Section */}
      <StyledPaper elevation={3} style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#00ffcc' }}>
          Tracce in Evidenza
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, marginTop: 2 }}>
          {featuredBars.length === 0 ? (
            <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
              Nessuna traccia in evidenza. Seleziona le tue migliori tracce!
            </Typography>
          ) : (
            featuredBars.map((bar) => (
              <Box
                key={bar.id}
                sx={{
                  width: 150,
                  height: 150,
                  position: 'relative',
                  bgcolor: '#2b2b2b',
                  p: 1,
                  borderRadius: 2,
                  boxShadow: '0px 0px 10px #00ffcc'
                }}
              >
                <Typography variant="body2" sx={{ color: '#00ffcc', fontWeight: 'bold' }}>
                  {bar.title || 'Traccia Sconosciuta'}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </StyledPaper>

      {/* User's Bars Collection */}
      <StyledPaper elevation={3} style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#00ffcc' }}>
          Le Tue Tracce
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop: 2 }}>
          {bars.map((bar) => (
            <Paper
              key={bar.id}
              elevation={2}
              sx={{
                p: 3,
                bgcolor: '#1c1c1c',
                borderRadius: '15px',
                boxShadow: '0px 0px 15px #333'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: '#00ffcc', textShadow: '0px 0px 10px #00ffcc' }}
                >
                  {bar.title || 'Traccia Senza Titolo'}
                </Typography>
                <Box>
                  <IconButton sx={{ color: '#ff4d4d' }}>
                    <Favorite />
                  </IconButton>
                  <IconButton sx={{ color: '#00ffcc' }}>
                    <Comment />
                  </IconButton>
                </Box>
              </Box>

              {/* Comment Section - gestita solo in locale */}
              <Box sx={{ mb: 2 }}>
                {(comments[bar.id] || []).map((comment, index) => (
                  <Typography key={index} variant="body2" sx={{ color: '#cfcfcf' }}>
                    <strong>{comment.userName}:</strong> {comment.text}
                  </Typography>
                ))}
              </Box>

              {/* Input per nuovo commento */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Aggiungi un commento..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  sx={{
                    input: { color: '#fff' },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: '#00ffcc',
                      },
                      "&:hover fieldset": {
                        borderColor: '#b3ffe1',
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddComment(bar.id)} 
                  sx={{
                    ml: 2,
                    bgcolor: '#00ffcc',
                    color: '#000',
                    '&:hover': { bgcolor: '#00e0b3' }
                  }}
                >
                  Invia
                </Button>
              </Box>
            </Paper>
          ))}
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default UserProfile;
